import canada from "../../assets/img/ctr/Canada.png";
import china from "../../assets/img/ctr/China.png";
import drc from "../../assets/img/ctr/DRC.png";
import grenada from "../../assets/img/ctr/Grenada.png";
import malaysia from "../../assets/img/ctr/Malaysia.png";
import oman from "../../assets/img/ctr/Oman.png";
import phillippines from "../../assets/img/ctr/Philippines.png";
import png from "../../assets/img/ctr/PNG.png";
import uae from "../../assets/img/ctr/UAE.png";
import america from "../../assets/img/ctr/America.png";
import argentina from "../../assets/img/ctr/argentina.png";
import alberta from "../../assets/img/ctr/Alberta-Flag.jpg";

export const partnershipsBannerData = {
  title: "Partnershios",
  description: "Cotnact us come on dude",
  videoLink:
    "https://s7e5a.scene7.com/is/content/neomstage/_Neom_Careers_Hero%20Video_compressed_v101",
};

export const partnershipData = [
  {
    id: 1,
    title: "Canada",
    content: "Created the largest Indigenous environmental company monitoring the Oilsands, Indigenous Nation debt restructuring, Financial and Technical Consulting and Engineering for governments and Indigenous Nations, Feasibility Studies, Royalty Agreements.",
    icon: canada,
    value: "",
  },
  {
    id: 2,
    title: "United States",
    content: "Forged partnerships with Direct Lithium Extraction leaders to explore and potentially commercialize Lithium Brine assets.",
    icon: america,
    value: "",
  },
  {
    id: 3,
    title: "Argentina",
    content: "Forged partnerships with Industry leaders to evaluate multiple Direct Lithium Extraction technologies, explored large asset base and formulated plans to commercialize large acreages in close proximity to essential infrastructure.",
    icon: argentina,
    value: "",
  },
  {
    id: 4,
    title: "Oman",
    content: "Advisory service for restructuring mandate in Oman.",
    client: "-Confidential Client-",
    icon: oman,
    value: "-Enterprise Value of US$475 million-",
  },
  {
    id: 5,
    title: "United Arab Emirates",
    content:
      "Ongoing advisory service for the development of new businesses into the United Arab Emirates.",
    client: "-Seven Emirates-",
    icon: uae,
    value: "",
  },
  {
    id: 6,
    title: "Grenada",
    content:
      "Advisory service for modeling of offshore blocks for potential joint venture or monetization.",
    client: "-Sistema Capital-",
    icon: grenada,
    value: "-Value of +US$2 billion-",
  },
  {
    id: 7,
    title: "China",
    content: "Advisory service for biomass investment and LNG facility model.",
    client: "-Henan Sunrise Holding Group-",
    icon: china,
    value: "",
  },
  {
    id: 8,
    title: "Papua New Guinea",
    content: "Advisory service for biomass investment.",
    client: "-Confidential Client-",
    icon: png,
    value: "",
  },
  {
    id: 9,
    title: "DRC",
    content: "Advisory service for biomass investment.",
    client: "-Confidential Client-",
    icon: drc,
    value: "-Enterprise Value of US$475 million-",
  },
  {
    id: 10,
    title: "Government of Alberta",
    content:
      "Financial advisor for the development of the Green Loan Guarantee Program (GLGP) both for Indigenous communities as well as small/medium/large scale emitters. In addition completed program design work on the Property Assessed Clean Energy (PACE) program.",
    client: "-Government of Alberta-",
    icon: alberta,
    value: "-$400 million of Loan Guarantees-",
  },
  {
    id: 11,
    title: "Malaysia",
    content:
      "Pending advisory service for biomass investment for both gas and liquid biofuel production.",
    client: "-Confidential Client-",
    icon: malaysia,
    value: "",
  },
  {
    id: 12,
    title: "Phillippines",
    content:
      "Pending advisory service for biomass investment for alternative products production.",
    client: "-Confidential Client-",
    icon: phillippines,
    value: "",
  },
];
