import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "components/portfolio/PortfolioItemOne";
import sectorsData from "../../../data/sectors-comp/sectors";
import { sectorsImgs } from "../../../data/sectors-page/sectorsImgs";
const sectorImages =
  window.location.pathname === "/indigenous" ||
  window.location.pathname === "/" ||
  window.location.pathname === ""
    ? sectorsImgs
    : sectorsData[window.location.pathname] &&
      sectorsData[window.location.pathname].sectorsImgs;
const PortfolioGrid = ({ wide, imgs }) => {
  const middleImg = 2;
  return (
    <div className="brook-portfolio-area pl--30 pr--30 pt--60 pb--60 bg--lines-white bg_color--1">
      <div fluid={!!wide} className={wide ? "plr--30" : ""}>
        <Row className="mt--n30 set-imgs--sectors">
          {imgs
            ? imgs.map((portfolio) => (
                <Col
                  sm={6}
                  lg={4}
                  key={portfolio.id}
                  className={`mt--30 ${
                    portfolio.id === middleImg
                      ? "middleImg--sectors"
                      : "sideimg--sectors"
                  }`}
                >
                  <PortfolioItem
                    title={portfolio.title}
                    thumb={portfolio.thumb}
                  />
                </Col>
              ))
            : sectorImages
            ? sectorImages.map((portfolio) => (
                <Col
                  sm={6}
                  lg={4}
                  key={portfolio.id}
                  className={`mt--30 ${
                    portfolio.id === middleImg
                      ? "middleImg--sectors"
                      : "sideimg--sectors"
                  }`}
                >
                  <PortfolioItem
                    title={portfolio.title}
                    thumb={portfolio.thumb}
                  />
                </Col>
              ))
            : null}
        </Row>
      </div>
    </div>
  );
};

export default PortfolioGrid;
