import vid from "../../assets/videos/about.m4v";

// import dark from "../../assets/img/dark.jpg";
import Sameer from "../../assets/img/about/Sameer.jpeg";
import Christopher from "../../assets/img/about/Christopher.jpeg";
import Colin from "../../assets/img/about/Colin.jpeg";
import eCarbon from "../../assets/img/about/ecarbon-logo.jpg";
import heliosx from "../../assets/img/about/heliosx.jpg";
import Manish from "../../assets/img/about/Manish.jpg";
import Scott from "../../assets/img/about/Scott.jpg";
import logo from "../../assets/img/logo/helios-logo.png";
export const aboutBannerData = {
  title: "About",
  description: "Know more about helios",
  videoLink: vid,
};

export const aboutParagraphData = {
  title: "About helios",
  logo: logo,
  more: [
    `Helios Corporation (Helios) was created in 2016 to raise the Environmental, Social and Governance (ESG) standard through positive environmental leadership initiatives. Management of Helios has significant international experience, and in 2016, decided to apply this global expertise to our own First Nations in Canada. There was a lack of understanding on the commercial application of new technologies and discovered an opportunity to enable domestic application of the latest in transition technologies.`,
    `Helios has a proven history of organic wealth creation with a focus on effective execution of innovative investment opportunities. Our laser focus has been on energy, infrastructure, technology and environmental services.`,
    `We apply our financial and technical expertise in partnerships to both advise and partner with new emerging energy or technology corporations. We believe new technologies such as blockchain and Web3 will be the next generation application of B2B opportunities. IoT applications continue to grow and Helios will work towards effective integration of Ai for enhanced analytics.`,
  ],
};

export const aboutTeamData = [
  {
    name: "Christopher Brown",
    designation: "Managing Partner",
    email: "info@helioscorp.ca",
    location: "",
    id: 1,
    img: Christopher,
    desc: `Mr. Christopher Brown has 25 years of experience as a professional engineer working in the global capital markets and energy/infrastructure operations. Has over 10 years of exclusively researching international energy and infrastructure companies for the purpose of institutional investments. Mr. Brown has been focused on supporting the applications of new technologies and corporate restructuring of new investments, with an ability to simplify complex concepts for institutional investors. Previous board member of a Municipal Telecommunication company which saw its way through to a successful corporate sale to TELUS. He has a working understanding of blockchain technologies and worked with clients on tokenization initiatives which include various technology and exchange reviews. He has been involved with over $5 billion of equity financings. Mr. Brown has a Bachelor of Science in Chemical Engineering from the University of Calgary.`,
  },
  {
    name: "Sameer Uplenchwar",
    designation: "Managing Partner",
    email: "info@helioscorp.ca",
    location: "",
    id: 2,
    img: Sameer,
    desc: `Mr. Sameer Uplenchwar has over 20 years of financial and business development experience. Mr. Uplenchwar’s expertise is in financial structuring and modelling as well as energy banking. With significant depth of contacts within the Canadian and US energy sector Mr. Uplenchwar generates significant deal flow opportunities for investors. Previously, Mr. Uplenchwar served as Managing Director and Head of US Equity Research with GMP Capital LLC in Houston, Managing Director with Global Hunter Securities in Calgary/Houston and VP at Morgan Stanley in New York.`,
  },
  {
    name: "Manish Bindal",
    designation: "Environmental and Compliance",
    email: "info@helioscorp.ca",
    location: "",
    id: 2,
    img: Manish,
    desc: `Mr. Bindal has worked in the Securities industry since 1985 and is responsible for all of the Helios Group of Companies’ regulatory filings, annual general meetings and corporate governance. He ensures all regulatory filings, disclosures, annual information forms, management information circulars and interim reports are in good order. He also liaises with: transfer agents, lawyers, accountants, auditors, stock exchanges and securities regulators. His wealth of corporate governance experience assists the Board of Directors with: committee meetings, drafting materials and resolutions, managing logistics, maintaining corporate minute books, recording minutes, managing SEDAR filings, coordinating virtual meeting platforms and updating policies and procedures.`,
  },
  {
    name: "Colin Penner",
    designation: "Operations",
    email: "info@helioscorp.ca",
    location: "",
    id: 4,
    img: Colin,
    desc: `Mr. Penner has over 20 years of business and operations management experience. He has extensive experience creating and supervising teams and engaging with multiple stakeholder groups during project implementation and operations. Mr. Penner has decades of experience working in the oil and gas, mining, and construction industries. Mr. Penner has over 8 years of experience working with blockchain technology and has a comprehensive understanding of global crypto-exchanges, tokenization, fintech and practical applications for near term use. He is a graduate from the Haskayne School of Business at the University of Calgary.
    `,
  },
];

export const projectsDataIndi = [
  {
    heading: "eGrowth Corp.",
    text: "Helios has partnered with eGrowth Corp. for the development of the Next Generation Geothermal Greenhouse. The Company has completed detailed engineering designs and secured locations in Alberta for its first greenhouse projects. The greenhouse would be the first of its kind integrating multiple commercial technologies (membrane structure + geothermal earth battery design + integrated control systems). Our design has a 1,140-tonne reduction in GHG emissions compared to a traditional/structured greenhouse. eGrowth has developed a year-round solution for food sovereignty with a net negative carbon footprint.",
    icon: eCarbon,
  },
  // {
  //   heading: "HeliosX",
  //   text: "HeliosX is an integrated lithium exploration company incorporating the latest in Direct Lithium Extraction (“DLE”) technologies to unlock 560,000 acres of potential commercial opportunities in Jujuy (Argentina), Nevada (USA) and Alberta (Canada). The Company has commercial agreements to utilize the latest in environmental technologies to remediate old mine tailings while extracting commercial precious metals, and plans to own and operate the first iodine-based gold concentrate extraction facility in North America.",
  //   icon: heliosx,
  // },
  {
    heading: "Seven emirates",
    text: "Seven Emirates is a wholly own subsidiary of SBK Holding which is the holding company of His Highness Dr. Sheikh Sultan Bin Khalifa Bin Zayed Al Nahyan. The partnership is to introduce new opportunities to the United Arab Emirates that would be considered valuable services. Helios Corporation's relationship could facilitate many investment opportunities within the UAE.",
    icon: "https://0901.nccdn.net/4_2/000/000/056/7dc/sbk-521x188-320x115.jpg#RDAMDAID935637",
  },
  // {
  //   heading: "Kaizen Oil Corporation",
  //   text: "Kaizen Oil Corporation has offices in California and Nevada and are partnered with Helios Corporation in the Canzen Oil joint venture. Kaizen Oil has extensive institutional contacts to facilitate energy transactions in Canada. Currently partnered on a 1,000 boe/d (60% oil) exclusive investment opportunity in Alberta, Canada. Kaizen Oil continues to seek new acquisition opportunities in the Western Canadian Sedimentary Basin.",
  //   icon: "https://0901.nccdn.net/4_2/000/000/05a/a3f/kaizen-320x28.jpg#RDAMDAID761455",
  // },
  {
    heading: "CarbonHood",
    text: "Connecting agricultural innovation with corporate environmental goals. The Company has over 930 million carbon credits available for purchase with projects in over 80 countries. Mission is to transform the global asset market by providing a secure, transparent, and trusted platform for certifying and managing a wide range of real-world assets, from carbon credits to land and minerals. Global partnerships include Goldman Sachs, Alphabet, and Nutrien.",
    icon: "",
  },
  // {
  //   heading: "ETIA Ecotechnologies",
  //   text: "For next generation renewable technology to convert biomass to electricity or renewable natural gas (RNG), highly recommend partnering with ETIA Ecotechnologies based out of France. ETIA brings proprietary pyrolysis technology combined with leading edge process engineering design for pyrolysis gas cleanup. Applications in both British Columbia to maximize both carbon credits combined with FortisBC commitment of upwards of $30/GJ for RNG 20-year contracts, as well as in California for RNG contracts that can be in excess of US$35/GJ to capitalize on the technology.",
  //   icon: "https://0901.nccdn.net/4_2/000/000/03f/ac7/ETIA-2-160x152.jpg#RDAMDAID1540183",
  // },
  // {
  //   heading: "Titan Oil Recovery",
  //   text: `Titan Oil Recovery partnership is done through Canzen Oil. Canzen holds the preferential agreement for the use of Titan's patented EOR application in Canada. The joint venture was formed to specifically acquire undervalued Canadian oil assets.
  //   Titan process is an organic, environmentally friendly, microbial enhanced oil recovery process. Titan's recovery process works with the existing natural ecology within the oil reservoir to stimulate the microbial growth and improve the recovery.`,
  //   icon: "https://0901.nccdn.net/4_2/000/000/06b/a1b/b02a4d33708d48abb9e3756ff5898f7a-191x217-191x217.jpg",
  // },
  // {
  //   heading: "United Oil & Gas",
  //   text: "UOGC (uogc.com) has a proven track record since 1991 of providing geosteering, geo-modeling and reservoir engineering services and software. The company has completed projects both in North America and International, and has developed all of its software and technology in-house. UOGC is an expert at reservoir studies and its proprietary SMART4D technology has been involved with over 1000 horizontal wells. The SMART4D geosteering software has been developed to allow operators and exploration companies to geosteer their own wells.",
  //   icon: "https://0901.nccdn.net/4_2/000/000/081/4ce/uogc-320x74.jpg#RDAMDAID1439949",
  // },
];
