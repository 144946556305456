import sectorsData from "../../data/sectors-comp/sectors";

const navbarData = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "Sectors",
    link: "",
    subMenu: false,
    megaMenu: [
      {
        id: 3.1,
        lists: sectorsData,
      },
    ],
  },
  {
    id: 3,
    title: "Indigenous",
    link: "/indigenous",
  },
  {
    id: 4,
    title: "Projects",
    link: "/projects",
  },
  {
    id: 5,
    title: "About",
    link: "/about-us",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact",
  },
];

export default navbarData;
