import React from "react";

const GradationItem = ({ count, color }) => {
  return (
    <div className="item-grid wow move-up">
      <div className="line" />
      <div className="dot-wrap">
        <div className="dot">
          <div className="count">{count}</div>
        </div>
      </div>
    </div>
  );
};

export default GradationItem;
