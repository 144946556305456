import svg1 from "../../assets/svg/svgexport-2.svg";
import svg2 from "../../assets/svg/svgexport-3.svg";
import svg3 from "../../assets/svg/svgexport-4.svg";
import svg4 from "../../assets/svg/svgexport-5.svg";

import asvg1 from "../../assets/svg/svgexport-2.svg";
import asvg2 from "../../assets/svg/svgexport-3.svg";

import pdfHeliosX from "../../assets/pdf/heliosx.pdf";

// Images for bg
import bg1 from "../../assets/img/sectors/energy/energy-main.png";
import bg2 from "../../assets/img/sectors/carbon/carbon-main.jpg";
import bg3 from "../../assets/img/sectors/fiber/fiber-main.jpg";
import bg4 from "../../assets/img/sectors/mining/mining-main.jpg";

import energy1 from "assets/img/sectors/energy/1.jpeg";
import energy2 from "assets/img/sectors/energy/2.jpg";
import energy3 from "assets/img/sectors/energy/3.jpg";
import carbon1 from "assets/img/sectors/carbon/1.png";
import carbon2 from "assets/img/sectors/carbon/2.png";
import carbon3 from "assets/img/sectors/carbon/3.png";
import fiber1 from "assets/img/sectors/fiber/1.png";
import fiber2 from "assets/img/sectors/fiber/2.png";
import fiber3 from "assets/img/sectors/fiber/3.png";
import mining1 from "assets/img/sectors/mining/1.png";
import mining2 from "assets/img/sectors/mining/2.png";
import mining3 from "assets/img/sectors/mining/3.png";

import secEnergy from "assets/svg/energy.png";
import secCarbon from "assets/svg/carbon.png";
import secFiber from "assets/svg/fiber.png";
import secMining from "assets/svg/mining.png";
import heliosx from "assets/img/client/heliosx.jpg";

const sectorsData = {
  "/sector/energy": {
    to: "/sector/energy",
    innersvgdbefore: secEnergy,
    innersvgdafter: secEnergy,
    bgimg: bg1,
    id: "3.1.1",
    head: "Our Services",
    title: "Energy",
    paragraphContent:
      '<p class="wrapped-text">The Helios Team bring over 100 years of technical, financial modelling, production sharing agreements, concession or service agreements to every investment opportunity. We have access to professional engineers, geologists and geophysicists that can be deployed domestically or internationally and have completed technical and financial modelling in more than 85 countries. Much of our work has ensured global institutions make appropriate risk/reward investment decisions based on comprehensive analysis of each jurisdiction. The team has participated in over $5 billion of institutional investments in upstream, midstream, and downstream energy mandates.<br /> <br />Helios has partnered on low-risk energy investments that are projected to produce reliable income for over 50 years with no long-term liability risk.</p> <p class="wrapped-text">Partnering with a global network of experts committed to success. We strive to provide solutions to opportunities for a full spectrum of services offered, where we can advise, finance, manage, operate.</p>',
    listContent: [],
    link: "/sector/energy",
    icon: secEnergy,
    image: bg1,
    color2: "#205d66",
    color1: "#2c818d",
    sectorsImgs: [
      {
        id: 1,
        thumb: energy1,
      },
      {
        id: 2,
        thumb: energy3,
      },
      {
        id: 3,
        thumb: energy2,
      },
    ],
    serviceData: [
      {
        title: "Invest With Low Risk",
        description:
          "Provide capital to junior energy companies to re-invest in low-risk field development investments",
      },
      {
        title: "Partnership Opportunities",
        description:
          "Application of new technologies on energy development investments including the use of enhanced oil recovery techniques inclusive of advanced drilling/completion techniques to water, polymer and microbial enhanced oil recovery partnerships.",
      },
      {
        title: "Tax Efficient Energy Investments",
        description:
          "Our tax efficient investment model utilities tax pools, flow through and limited liability corporations to minimize or eliminate taxation.",
      },
      {
        title: "Intellectual Property and Other Services",
        description:
          "Domestic and international field development Audit and Design. Additionally, the team is set up to review and optimize the of modelling Production Sharing Agreements, Concession Agreements, Service Agreements and include technical Audit of Cost Recovery.",
      },
    ],
  },
  "/sector/carbon-reduction": {
    to: "/sector/carbon-reduction",
    innersvgdbefore: secCarbon,
    innersvgdafter: secCarbon,
    bgimg: bg2,
    id: "3.1.2",
    head: "eGrowth at a Glance",
    title: "CARBON REDUCTION",
    link: "/sector/carbon-reduction",
    icon: secCarbon,
    image: bg2,
    color2: "#0086b3",
    color1: "#0096c7",
    paragraphContent:
      '<p class="wrapped-text">Agricultural technology (Agri-Tech) is revolutionizing food production, making farming more efficient, sustainable, and resilient. With global challenges such as climate change, population growth, and resource limitations, leading Agri-Tech solutions are essential for ensuring food security, environmental sustainability, and economic growth. </br></br> As farmers often struggle to afford expensive agricultural innovations such as: precision farming tools (i.e. drones, AI analytics), smart irrigation systems, high-yield seeds, renewable energy, there will emerge new lending models that include micro-loans, fintech-driven credit systems and blockchain-based financing.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: carbon1,
      },
      {
        id: 2,
        thumb: carbon2,
      },
      {
        id: 3,
        thumb: carbon3,
      },
    ],
    serviceData: [
      {
        title: "Our Mission",
        description:
          "Harnessing the power of geothermal energy and advanced IoT analytics to create sustainable, climate-resilient, and energy-efficient greenhouses. Our mission is to revolutionize food production by integrating smart technology, reducing carbon footprints, and ensuring year-round, eco-friendly agriculture for a healthier and more food-secure future.",
      },
      {
        title: "Year-Round Sustainability",
        description:
          "Our proprietary engineering design enables geothermal energy to naturally regulate temperatures in extreme climates, ensuring year-round food security.",
      },
      {
        title: "IoT and Advanced Analytics utilizing AI for Smart Farming",
        description:
          "IoT sensors monitor temperature, humidity, and soil conditions in real time with AI-driven analytics optimizing water usage, light exposure, and CO₂ levels. Our goal is to ultimately integrate predictive analytics that will enable automated greenhouse adjustments, boosting efficiency and sustainability.",
      },
      {
        title: "Our Goal",
        description:
          "Reduce dependency on traditional farming that is vulnerable to climate change. Our carbon negative farming model can provide sustainable solutions to urban and rural food production. Our business model can empower our partners to tokenize the carbon credits created.",
      },
    ],
  },
  "/sector/web3-blockchain-ai": {
    to: "/sector/web3-blockchain-ai",
    innersvgdbefore: secFiber,
    innersvgdafter: secFiber,
    bgimg: bg3,
    id: "3.1.3",
    head: "Our Services",
    title: "WEB3-BLOCKCHAIN-AI",
    link: "/sector/web3-blockchain-ai",
    icon: secFiber,
    image: bg3,
    color2: "#996600",
    color1: "#b37700",
    extra: {
      miniTitleBasic: "Our Clients",
      subHeading: "CarbonHood Group of Companies",
      description: [
        "<p><b>CarbonHood Royalty Corporation</b> <br />Specializes in purchasing and providing verified carbon credits globally for eco-conscious corporations and clients seeking to offset their carbon footprints. We guarantee the purchase of all carbon credits generated through our extensive network of agricultural farms, clients, and strategic joint ventures. By partnering with global foundations and CEOs, we ensure a steady supply of high-quality, verified carbon credits that can be used to meet sustainability goals and regulatory requirements.</p>",
        '<p><b>CarbonHood Registry</b> <br /> Dedicated to providing a secure, transparent, and blockchain-powered platform for the validation, registration, and management of a diverse array of real-world assets, with a focus on tokenized carbon credits. We are "The Single Source of Truth," ensuring asset integrity and driving confidence in global markets by providing next-generation assurance.<br />Utilizing an innovative blockchain-powered ecosystem, CH Registry deliver an immutable and auditable record of asset ownership, provenance, and transactions, enhancing transparency for project developers, corporations, and investors. The platform streamlines the certification and registration process, empowering participation in the global market for carbon credits, land, minerals, insurance, and project-related resources.<br />A Web3 blockchain registry offers transformative advantages for managing carbon credits, critical for addressing climate change and promoting sustainability. By utilizing blockchain technology, this system brings transparency, security, and efficiency while enhancing access to carbon markets.</p>',
        "<p><b>CarbonHood Terra Corporation</b> <br /> Dedicated to acquiring land in developing countries to foster more sustainable agriculture, aquaculture, and reforestation projects. By transforming underutilized land into eco-friendly, revenue-generating opportunities, we not only boost local economies but also contribute to global sustainability goals. Our focus lies in integrating regenerative farming, sustainable fisheries, and large-scale reforestation, all aimed at enhancing environmental health while creating tangible financial returns for stakeholders.</p>",
        "<p><b>CarbonHood Insurance Corporation</b> <br /> Committed to safeguarding the integrity and financial viability of carbon credit projects worldwide. Recognizing the inherent risks in the carbon market, we provide innovative insurance solutions that protect investments, enhance credibility, and foster confidence for both project developers and credit purchasers. Our focus is on mitigating risk and ensuring the long-term stability of carbon initiatives.</p>",
        "<p><b>CarbonHood Clean Development</b> <br /> Committed to supporting agricultural farmers and clients by providing contracts and subsidies to generate carbon credits exclusively. Our approach ensures that farmers can implement eco-friendly practices, increase soil carbon sequestration, and reduce emissions, all while receiving guaranteed financial support through subsidies. By enabling farmers to participate in the growing carbon credit market, we create a new revenue stream for them, while driving environmental benefits globally.</p>",
      ],
    },
    paragraphContent:
      '<p class="wrapped-text">Today, we stand at the frontier of a technological revolution that is reshaping industries, economies, and the way we interact with digital systems—<b>Blockchain Technology.</b><br /><br />From its origins as the backbone of cryptocurrencies like Bitcoin to its expanding role in finance, supply chains, healthcare, and even governance, blockchain is proving to be more than just a buzzword—it is a paradigm shift. <br /><br />Web3, the decentralized evolution of the internet, has the potential to significantly transform various industries and societal structures. Built on blockchain and decentralized technologies, Web3 shifts control from centralized authorities to individuals, fostering greater autonomy, security, and efficiency.<br /><br />But what makes blockchain and Web3 so transformative?</p><ul class="agri-tech-ul"><li class="agri-tech-li"><b>Decentralization</b> – Removing the need for intermediaries</li><li class="agri-tech-li"><b>Transparency</b> – Creating a tamper-proof digital ledger</li><li class="agri-tech-li"><b>Security</b> – Strengthening trust through cryptographic protection</li><li class="agri-tech-li"><b>Efficiency</b>– Automating Processes and reducing costs</li></ul><p class="wrapped-text">As businesses and governments worldwide explore blockchain’s potential, the question is no longer if it will revolutionize industries, but how fast and how far its impact will spread.<br /><br /> Our team brings eight years of experience in blockchain technology understanding and its integration with many exchange platforms. We can assess and evaluate strategic partnerships within the blockchain universe to determine the optimal strategic partnership to allow businesses to unlock future revenue streams.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: fiber1,
      },
      {
        id: 2,
        thumb: fiber3,
      },
      {
        id: 3,
        thumb: fiber2,
      },
    ],
    serviceData: [
      {
        title: "Expert Guidance & Market Insights",
        description:
          "Blockchain is a complex and evolving space. A knowledgeable group can provide strategic insights, technical analysis, and market trends to help investors make informed decisions. Access to experienced professionals reduces the risk of misinformation and enhances investment clarity.",
      },
      {
        title: "Risk Management & Due Diligence",
        description:
          "Investing in blockchain involves risks such as regulatory uncertainty, security threats, and volatile markets. A group can conduct thorough research, project vetting, and risk assessments, ensuring investors avoid scams, unstable projects, or poorly managed ventures.",
      },
      {
        title: "Access to Exclusive Opportunities & Networks",
        description:
          "Many high-value blockchain projects offer early-stage investment opportunities that are not publicly available. A well-connected group can provide direct access to blockchain startups, private sales, and strategic partnerships, maximizing investor potential.",
      },
      {
        title: "Portfolio Diversification & Strategy Development",
        description:
          "Blockchain investments go beyond cryptocurrencies—they include DeFi (Decentralized Finance), smart contracts, and enterprise blockchain solutions. A group can help investors build a balanced, diversified portfolio, aligning with their financial goals and risk tolerance.",
      },
    ],
  },
  "/sector/mining-extraction": {
    to: "/sector/mining-extraction",
    innersvgdbefore: secMining,
    innersvgdafter: secMining,
    bgimg: "/www/images/sectors/mining/banner_page.png",
    id: "3.1.4",
    head: "Our Services",
    title: "Mining and Extraction",
    link: "/sector/mining-extraction",
    icon: secMining,
    image: bg4,
    color2: "#e0bd5e",
    color1: "#b99732",
    extraMore: [
      // {
      //   title: "Dajin Lithium Corp",
      //   link: "www.dajin.ca",
      //   url: "https://www.dajin.ca",
      //   logo: "https://dajin.ca/static/media/logo.37fd99c1.png",
      //   more: [
      //     `Under HeliosX, a managed entity by Helios management group, we are working to partner with Dajin Lithium Corp (Dajin). Dajin holds a 49% interest in concessions or concession applications held by Dajin Resources S.A. (Dajin S.A.) in Jujuy Province, Argentina that were acquired in regions known to contain brines with Lithium, Potassium and Boron values.`,
      //     `These land holdings exceed 93,000 hectares (230,000 acres) and are primarily located in the Salinas Grandes and Guayatayoc salt-lake basins. Dajin is partnered with Pluspetrol Resources Corporation B.V. who through an Earn-in agreement have earned a 51% interest in Dajin S.A. with the expenditure of US$1,250,000 on the concessions and paying US$600,000 to Dajin. The initial 2018 Salinas Grandes exploration program on 4,400 hectares (10,873 acres) on the San Jose-Navidad minas, where 25 near surface Lithium brine samples were taken, returned excellent Lithium assays results ranging from 281 mg/l to 1,353 mg/l averaging 591 mg/l`,
      //     `In the United States, Dajin holds a 100% interest in 403 placer claims covering 7,914 acres (3,202 hectares) in the Teels Marsh Valley of Mineral County, Nevada. These claims are known to contain Lithium and Boron values. Dajin has entered into an Earn-in Agreement with Lone Mountain Resources, LLC, a privately held US Lithium exploration and development company affiliated with Lilac Solutions, Inc. Lilac is a well-funded Lithium extraction company located in Oakland, California. LMR can earn a 75% interest in Alkali Lake Lithium project with project related expenditures of US$200,000. An exploration well is to be drilled to a depth of 1,500 feet`,
      //   ],
      // },
      {
        title: "HeliosX Corp",
        logo: heliosx,
        // link: "www.heliosx.ca",
        // url: "https://www.heliosx.ca",
        more: [
          `Management Team created HeliosX Lithium & Technologies Corp and grew the asset base to more than 830,000 acres of highly prospective lithium Brine assets in Argentina, the USA and Canada.  Partnered with industry leaders in Direct Lithium Extraction technology and conducted detailed geological modelling and Brine production simulation and optimization. `,
        ],
        // moreinfo: "www.heliosx.ca",
      },
      {
        title: "EnviroMetal Technologies inc.",
        link: "www.envirometal.com",
        url: "http://www.envirometal.com",
        logo: "https://envirometal.com/wp-content/uploads/2021/07/em-logo-green.png",
        more: [
          `EnviroMetal’s proprietary and patented chemistry technology extracts precious and other valuable metals from conventional mine concentrates and printed circuit board assemblies. Envirometal’s proprietary technology is cost-effective, environmentally sustainable and a domestic alternative to the use of cyanide and smelters for the recovery of gold from primary and secondary sources.`,
        ],
      },
      {
        title: "EnviroGold Global",
        // link: "www.envirogoldglobal.com",
        // url: "https://www.envirogoldglobal.com",
        // logo: "https://www.envirogoldglobal.com/wp-content/uploads/2021/02/web_logo.png",
        more: [
          `EnviroGold leverages proprietary technology, operational knowledge and an agile, efficient culture to recover valuable metals, recharge critical natural resources and accelerate the world’s transition to a sustainable circular resource economy. They are strategically positioned to sustainably supply the increasing demand for precious, critical and strategic metals by profitably reclaiming precious metals from mine tailings and waste streams.`,
        ],
      },
    ],
    extra: {
      miniTitleBasic: "OUR FOCUS",
      logo: "",
      description: [
        "Will partner with producers and Indigenous nations to create an environmentally friendly solution.",
        "Partnering with industry to effectively restructure entities for lowering costs and improve institutional investor exposure.",
      ],
    },
    paragraphContent:
      '<p class="wrapped-text">Critical minerals are essential raw materials for modern technology, energy transition, and economic stability. These minerals—including lithium, cobalt, nickel, rare earth elements (REEs), and graphite—are used in electric vehicles (EVs), renewable energy storage, electronics, and defense systems. The demand for these resources is increasing rapidly due to advancements in green technology and digital transformation.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: mining1,
      },
      {
        id: 2,
        thumb: mining3,
      },
      {
        id: 3,
        thumb: mining2,
      },
    ],
    serviceData: [
      {
        title: "Restructuring Expertise",
        description:
          "Proven track record restructuring public companies to maximize shareholder value. Have completed detailed analysis for Boards for independent S.W.O.T. analysis. Work includes comprehensive corporate models and recommendations for profitable enhancements.",
      },
      {
        title: "Experienced Helios Team",
        description:
          "Proven track record of growing companies while respecting ESG initiatives. Have access to a range of expertise from technical through financial. Have end to end understanding of value chain that enables the clean energy transition.",
      },
      {
        title: "Equitable Developments",
        description:
          "Proven track record ensuring Indigenous Nations are properly compensated for developments on treaty lands and their voices actively guide responsible development.",
      },
      {
        title: "Integrating Blockchain and the latest Technologies ",
        description:
          "Helios can collaborate with mining companies to integrate the latest in blockchain and other advanced technologies, significantly enhancing the monetization of opportunities.",
      },
    ],
  },
};

export default sectorsData;
