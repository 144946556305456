import React from "react";
import Logo from "./elements/logo";
import { withRouter } from "react-router-dom";
import Navbar from "./elements/navbar/Navbar";
import HeaderContainer from "container/header/HeaderContainerOne";
import HeaderConfig from "components/header/elements/config/HeaderConfigOne";


import logo from "assets/img/logo/helios-logo.png";

const HeaderThree = (props) => {
  const pathname = props.location.pathname || ''
  const backgroundBlack = pathname === '/';
  return (
    <HeaderContainer logoVersion={"light"} transparent={true} sticky={true} backgroundBlack={backgroundBlack}>
      <div className="header-left flex-20">
        <Logo logo={logo} />
      </div>

      <div className="header-flex-right flex-80">
        <Navbar />
        <HeaderConfig />
      </div>
    </HeaderContainer>
  );
};

export default withRouter(HeaderThree);
