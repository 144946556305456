import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import ServiceItem from "components/service/ServiceItemThree";
import sectorsData from "../../data/sectors-comp/sectors";

const ServiceFive = (props) => {
  const { services } = props;
  const sectors =
    window.location.pathname === "/indigenous" ||
    window.location.pathname === "/" ||
    window.location.pathname === ""
      ? false
      : true;
  useEffect(() => {
    // const architectureServices = document.querySelectorAll(".hover-bg-img-4");
    // const serviceContainer = document.querySelector(
    //   ".hover-bg-img-4-container"
    // );
    // serviceContainer.style.backgroundImage = `url(${require("../../assets/img/slider/type/" +
    //   services[0].thumb)})`;
    // architectureServices.forEach((service, indx) => {
    //   service.addEventListener("mouseenter", () => {
    //     let thumb = require("../../assets/img/slider/type/" +
    //       services[indx].thumb);
    //     serviceContainer.style.backgroundImage = `url(${thumb})`;
    //   });
    // });
  }, []);
  return (
    <div className="hover-bg-img-4-container container-fluid">
      <Row>
        {sectors
          ? sectorsData[window.location.pathname].serviceData.map(
              (service, index) => (
                <ServiceItem
                  key={"service5" + index}
                  data={{ service: service, number: index }}
                />
              )
            )
          : services.map((service, index) => (
              <ServiceItem
                key={"service5" + index}
                data={{ service: service, number: index }}
              />
            ))}
      </Row>
    </div>
  );
};

export default ServiceFive;
