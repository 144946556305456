import React, { Component } from "react";

import { Helmet } from "react-helmet";
import LayoutDefault from "layouts/LayoutDefault";
import Power from "./power/Power";
import Energy from "./energy/Energy";
import Mining from "./mining/Mining";
import CarbonReduction from "./carbonReduction/CarbonReduction";
import helios from "data/helios.json";
import { homepageBannerData } from "data/home-page/homepage";
import { Container, Row, Col, Fade } from "react-bootstrap";
import SectorBanner from "./SectorBanner";
import Sectors from "components/sectors/Sectors";
import Services from "container/service/ServiceFive";
import CarouselContainer from "components/slider/CarouselContainer";
import data from "../../components/slider/carouselData.json";
import sectorsData from "data/sectors-comp/sectors";
import ApiUtils from "../../utils/ApiUtils";
import PortfolioGrid from "container/portfolio/pages/PortfolioGrid";
import HtmlParser from "html-react-parser";
import About from "container/about-us/AboutCreativeAgency";
import AboutDescription from "../about-us/AboutDescription";

import "./sector.scss";
export default class Sector extends Component {
  state = {
    url: window.location.pathnname,
  };
  componentDidMount() {
    window.scroll(0, 0);
    let elem = document.getElementById("onHoverClassAdd");
    elem.classList.remove("hover-bg-change");
    // let url = "https://api.publicapis.org/entries";
    // let type = "GET";
    // let payload = "";
    // return ApiUtils.globalAction(url, type, payload).then((res) => {
    //   if (res.status) {
    //     console.log(res);
    //     alert("found");
    //   } else {
    //     alert("went wronng");
    //   }
    // });
  }
  componentDidUpdate() {
    let elem = document.getElementById("onHoverClassAdd");
    elem.classList.remove("hover-bg-change");
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    // Action you want to execute
    if (window.location.pathname !== this.state.url) {
      this.setState({ url: window.location.pathname }, function () {
        window.scroll(0, 0);
      });
    }
  }
  render() {
    let url = window.location.pathname;
    let currentPageData = sectorsData[url];
    console.log("heeee", HtmlParser(currentPageData.paragraphContent));
    let section = "";
    let bgImage = currentPageData.bgimg;
    if (url === "/sector/power") {
      section = <Power />;
    } else if (url === "/sector/energy") {
      section = <Energy />;
    } else if (url === "/sector/mining") {
      section = <Mining />;
    } else if (url === "/sector/carbon-reduction") {
      section = <CarbonReduction />;
    }

    return (
      <>
        <Helmet>
          <title>{`Sectors | ${helios.title}`}</title>
        </Helmet>
        <LayoutDefault
          className="template-color-2 template-font-3"
          revealFooter={true}
        >
          <SectorBanner
            title={homepageBannerData.title}
            description={homepageBannerData.description}
            imageLink={bgImage}
          />
          <div
            id="scrollIntoId"
            className="about-body pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5"
          >
            <div className="pt--150 pb--30 pt_sm--100">
              <Container>
                <div className="about-section">
                  <Fade bottom>
                    <img
                      className="about-logo"
                      src={currentPageData.innersvgdbefore}
                      alt="Helios Logo"
                    />
                  </Fade>
                  <Fade bottom>
                    <h3 className="h-theme text-center">
                      {currentPageData.title}
                    </h3>
                  </Fade>
                  <Fade bottom>
                    <div className="p-theme center-wrap">
                      {HtmlParser(currentPageData.paragraphContent)}
                    </div>
                  </Fade>
                </div>
              </Container>
            </div>
          </div>
          <PortfolioGrid imgs={currentPageData.sectorsImgs} />

          {/* <Container>
            <Row>
              <Col xs={12} className="text-center">
                <div className="sector-Text">
                  <h1 className="sector-heading">Text</h1>
                </div>
                {section}
              </Col>
            </Row>
          </Container> */}
          {/* <ImageSwiper /> */}
          <Services />
          {currentPageData.extra ? (
            <About
              miniTitleBasic={currentPageData.extra.miniTitleBasic}
              subHeading={currentPageData.extra.subHeading}
              description={currentPageData.extra.description}
            />
          ) : (
            ""
          )}
          <Sectors />
        </LayoutDefault>
      </>
    );
  }
}
