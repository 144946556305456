import React from "react";
import Fade from "react-reveal/Fade";

function MoreInfo(props) {
  const { heading, text, icon, link, id } = props;
  return (
    <div className="more-info--container" id={id ? `${id}` : ""}>
      <div className="container">
        <div className="more-info--top">
          <div className="more-info--top-left">
            <Fade bottom>
              {icon && (
                <img
                  className="more-info--top-left-icon"
                  alt={heading}
                  src={icon}
                />
              )}
            </Fade>
          </div>
          <div className="more-info--top-right">
            <Fade top>
              <div className="more-info--top-right-top h-theme partnerships">
                {heading}
              </div>
            </Fade>
            <Fade right>
              <div className="more-info--top-right-bottom p-theme">{text}</div>
            </Fade>
            <Fade right>
              {link && (
                <>
                  <br />
                  <a
                    href={link}
                    style={{ color: "#b89535", textDecoration: "none" }}
                  >
                    Mail Us {`>`}
                  </a>
                </>
              )}
            </Fade>
          </div>
        </div>
        <div className="more-info--bottom"></div>
      </div>
    </div>
  );
}

export default MoreInfo;
