// import indiIcon from "../../assets/svg/indigenous.png";
import indi from "../../assets/img/client/indi.jpeg";
import partnerIcon from "../../assets/svg/partnership.png";
import investorIcon from "../../assets/svg/investor.png";
import video from "../../assets/videos/indigenous.m4v";
export const indigenousData = {
  title: "Indigenous",
  description: "Indigenous page and keep on moving",
  videoLink: video,
  isUnmute: false,
};

export const indigenousMoreInfo = {
  heading: "Indigenous",
  text: "Helios is committed to Reconciliation with Indigenous Nations and brings a wealth of Environmental, Social, Governance and Capital Markets experience to infrastructure projects that create wealth, jobs and self-determination. We think it is crucial to integrate Traditional Knowledge with the latest technology to mitigate environmental impact and ensure projects are designed with a comprehensive understanding from design through reclamation. We continuously seek equity partnerships for expanding applications of our expertise and technology.",
  icon: indi,
};

export const indiServices = {
  miniTitleBasic: "Our Services",
  description: [
    "Helios has successfully developed multiple, profitable, sustainable, Indigenous partnerships and seeks more with Nations who share our commitment to profitable commercial opportunities and environmental stewardship",
    "Complex Infrastructure guidance for Power, Fiber, Energy, Pipelines, and Renewables",
    "Capital Raise and Equity Negotiation with direct access to contacts at large Canadian and USA based financial institutions. ",
    "Consulting services to understand the First Nation Financial Management Board (FNFMB) and how to utilize the First Nation Finance Authority (FNFA)",
    "Helios provides turnkey service from initial conversations with the FNFMB through to development and implementation of policies.",
    "Consulting services for Indigenous Nation policy development, inclusive of Trust Agreement designs, to maximize current income while providing generational income.",
    "Renewable Technology Integration",
  ],
};

export const ourWork = {
  miniTitleBasic: "Our Work",
  description: [
    "Keynote speaker, along side the Premier of Alberta, in an industry presentation on how to finance new energy projects",
    "Advised the Government of Alberta on the design of a $400 million Indigenous specific, $1 billion Loan Guarantee Program, and assist Nations to utilize the Guarantees to access Capital",
    "Negotiated Commercial Terms for energy and infrastructure investments to maximize Indigenous participation with the lowest equity threshold",
    "Secured over $150 million of Term Sheets for Indigenous Nations to participate in large scale infrastructure opportunities",
    "Provide lower cost power to Nations through development of on and off Reserve resources, as well as integration of Renewable Technology",
    "Presented to the Indigenous Electricity Technical Working Group on how Nations can achieve power self-sufficiency and access Capital to develop the infrastructure",
  ],
};

export const IndiInvest = {
  heading: "INVESTORS",
  text: `Helios seeks strategic Nation and Industry partnerships. Our focus is profitable, sustainable and equitable investment opportunities in: Infrastructure, Technology, Environmental Services; and applications of our expertise and project management to enhance corporate ESG and maximize shareholder returns.
  If you want to partner or harness our management and technical expertise please contact:  investors@helioscorp.ca`,
  icon: investorIcon,
};
export const IndiPartnerships = {
  id: "partnerships",
  heading: "Partnerships",
  text: "Helios is committed to Reconciliation with Indigenous Nations and brings a wealth of Environmental, Social, Governance and Capital Markets experience to infrastructure projects that create wealth, jobs and self-determination. We think it is crucial to integrate Traditional Knowledge with the latest technology to mitigate environmental impact and ensure projects are designed with a comprehensive understanding from design through reclamation. We continuously seek equity partnerships for expanding applications of our expertise and technology.",
  icon: partnerIcon,
  link: "mailto: partners@helioscorp.ca",
};

export const Conferences = {
  id: "partnerships",
  heading: "Conference/Seminars",
  text: "Helios is available to present to either Chief and Council or Community presentation on all aspects of different approaches to develop socio-economic benefits for First Nation Communities, including and not limited to resource extraction opportunities, food sovereignty and application of renewable/clean energy.",
};

export const indiProjects1 = [
  {
    headingBlack1: "Big Island Lake Cree",
    headingBlack2: "Nation",
    headingGold1: "B.I.L. Cree",
    headingGold2: "Nation Corp.",
    para1:
      "Helios is managing the economic development entity for Big Island Lake Cree Nation called B.I.L Nation Corp.  Our focus on low risk, high return investments recently increased third party revenue by 300% on an investment that will pay dividends for more than 50 years.",
    para2:
      "B.I.L. Cree Nation Corp. is also focused on the development of high-speed fiber optic infrastructure and renewable technology for the Nation.",
  },
];
export const indiProjects2 = [
  {
    headingBlack1: "Athabasca",
    headingBlack2: "Chipewyan",
    headingGold1: "First Nation",
    // headingGold2: "Acden Helios.",
    para1:
      "Helios is an integral part of the management of Acden Helios.  Acden is the business group of the Athabasca Chipewyan First Nation.  This is the first Indigenous environmental company to focus on avian detection, monitoring and deterrence through the use of the latest in applied, intelligent radar systems.",
    para2:
      "Acden Helios has more than 100 employees comprised of biologists, engineers and skilled workers providing avian management and deterrent services to Suncor and Imperial Oil at Alberta’s Oilsands.",
    para3: "",
    para4: "",
  },
];
export const indiProjects3 = [
  {
    headingBlack1: "Meadow Lake",
    headingBlack2: "Tribal Council (MLTCII)",
    para1:
      "Helios has been engaged by Meadow Lake Tribal Council Industrial Investments to seek out new business opportunities.",
  },
];

export const projectsDataIndi = [
  {
    heading: "Seven emirates",
    text: "Seven Emirates is a wholly own subsidary of SBK Holding which is the holding company of HH Dr. Sheikh Sultan Bin Khalifa Bin Zayed Al Nahyan. The partnership is to introduce new opportunities to the United Arab Emirates that would be considered valuable services. Helios Corporation's relationship could facilitate many investment opportunities within the UAE. In addition, numerous opportunities for strategic mandates with ADNOC currently available. Please contact us for further information",
    icon: "https://0901.nccdn.net/4_2/000/000/056/7dc/sbk-521x188-320x115.jpg#RDAMDAID935637",
  },
  {
    heading: "Kaizen Oil Corporation",
    text: "Kaizen Oil Corporation has offices in California and Nevada and are partnered with Helios Corporation in the Canzen Oil joint venture. Kaizen Oil has extensive institutional contacts to facilitate energy transactions in Canada. Currently partnered on a 1,000 boe/d (60% oil) exclusive investment opportunity in Alberta, Canada. Kaizen Oil continues to seek new acquisition opportunities in the Western Canadian Sedimentary Basin.",
    icon: "https://0901.nccdn.net/4_2/000/000/05a/a3f/kaizen-320x28.jpg#RDAMDAID761455",
  },
  {
    heading: "ETIA Ecotechnologies",
    text: "For next generation renewable technology to convert biomass to electricity or renewable natural gas (RNG), highly recommend partnering with ETIA Ecotechnologies based out of France. ETIA brings proprietary pyrolysis technology combined with leading edge process engineering design for pyrolysis gas cleanup. Applications in both British Columbia to maximize both carbon credits combined with FortisBC commitment of upwards of $30/GJ for RNG 20-year contracts, as well as in California for RNG contracts that can be in excess of US$35/GJ to capitalize on the technology.",
    icon: "https://0901.nccdn.net/4_2/000/000/03f/ac7/ETIA-2-160x152.jpg#RDAMDAID1540183",
  },
  {
    heading: "Titan Oil Recovery",
    text: `Titan Oil Recovery partnership is done through Canzen Oil. Canzen holds the preferential agreement for the use of Titan's patented EOR application in Canada. The joint venture was formed to specifically acquire undervalued Canadian oil assets.
    Titan process is an organic, environmentally friendly, microbial enhanced oil recovery process. Titan's recovery process works with the existing natural ecology within the oil reservoir to stimulate the microbial growth and improve the recovery.`,
    icon: "https://0901.nccdn.net/4_2/000/000/06b/a1b/b02a4d33708d48abb9e3756ff5898f7a-191x217-191x217.jpg",
  },
  {
    heading: "United Oil & Gas",
    text: "UOGC (uogc.com) has a proven track record since 1991 of providing geosteering, geo-modeling and reservoir engineering services and software. The company has completed projects both in North America and International, and has developed all of its software and technology in-house. UOGC is an expert at reservoir studies and its proprietary SMART4D technology has been involved with over 1000 horizontal wells. The SMART4D geosteering software has been developed to allow operators and exploration companies to geosteer their own wells.",
    icon: "https://0901.nccdn.net/4_2/000/000/081/4ce/uogc-320x74.jpg#RDAMDAID1439949",
  },
  {
    heading: "eCarbon Corp.",
    text: "Helios has partnered with eCarbon Corp. to develop biomass facilities to convert wood waste to either electricity or renewable natural gas. eCarbon has a strategic partnership with HATCH engineering to complete all necessary FEED and detailed engineering design reviews. Current focus areas are Western Canada as well as California. eCarbon's focus is to utilize wood waste associated with pulp mills, but also forest damaged by forest fires and pine beetle damage. eCarbon continues to seek strategic partnerships with industry, municipalities and strategic Indigenous communities to move forward on pending projects.",
    icon: "https://0901.nccdn.net/4_2/000/000/01e/20c/eCarbon-on-white-131x132-131x132.jpg",
  },
  {
    heading: "Meadow Lake Tribal Council (MLTCII)",
    text: "Helios has been engaged by Meadow Lake Tribal Council Industrial Investments to seek out new business opportunities.",
    icon: "https://0901.nccdn.net/4_2/000/000/038/2d3/MLTCII-Logo-331x331.jpg",
  },
];
