import svg1 from "../../assets/svg/icons/svg-1.svg";
import svg2 from "../../assets/svg/icons/svg-2.svg";
import svg3 from "../../assets/svg/icons/svg-3.svg";
import svg4 from "../../assets/svg/icons/svg-4.svg";
import vid from "../../assets/videos/projects.m4v";
import rngPresentation from "../../data/footer/rng-presentation.pdf";
import bilcnPresentation from "../../data/footer/bilcn-presentation.pdf";

import sap from "../../assets/img/client/sapling.png";
export const projectsBannerData = {
  title: "Projects",
  description: "Cotnact us come on dude",
  videoLink: vid,
};

export const projects = [
  {
    id: "#big-island-lake-cree",
    headingBlack1: "eGrowth Corp",
    headingBlack2: "",
    headingGold1: "NEXT GENERATION GEOTHERMAL GREENHOUSE",
    //headingGold2: "Installation",
    para1:
      "Next Generation Geothermal Greenhouses (NGG) are state-of-the art, energy and resource efficient Agri-Tech platforms that harness geothermal energy for heating and cooling with vertical hydroponics to maximize crop yields. The synergistic suite of technology NGG implement reduce operational costs, ensure year-round production, and minimize environmental impact.",
    para2:
      "The Company uses adaptive building technologies, specialized building envelope, tower hydroponics combined with sustainable heating/cooling, dynamic lighting and sustainable water systems with AI climate control.",
    link: bilcnPresentation,
    cardsData: [
      {
        icon: sap,
        text: "Geo-exchange systems uses ground reservoir for seasonal storage of thermal energy",
      },
      {
        icon: sap,
        text: "Green House Gas reduction of ~1,100 tonnes – carbon negative footprint",
      },
      {
        icon: sap,
        text: "Addresses need for global food sovereignty and is a solution for regions impacted by climate change",
      },
    ],
  },
  {
    id: "#renewable-natural-gas",
    headingBlack1: "Sustainable",
    headingBlack2: "",
    headingGold1: "Energy Efficient Heating and Cooling",
    headingGold2: "",
    para1: `Utilizing the latest in geo-exchange design systems any company can now utilize the Earth as a heat sink battery system. A shallow development program allows for accelerated permit approvals, as the goal is thermal for heating/cooling purposes only (not electricity).`,
    para2:
      "eGrowth unique building envelope is light-weight but strong enough to resist hail and hurricane level wind events. The envelope is fully recyclable and has more than 25-year lifespan. The membrane is R25 equivalent insulation and UV, water and mildew resistant. The light weight structure allows for accelerated construction time which greatly lowers labour costs.",
    para3:
      "Water conservation is a priority for the design. eGrowth design utilizes 95-99% less water than traditional farming techniques. The internal water recirculation system employs a hydroponic drip irrigation system. Combined with a dehumidification system, there is no lost water due to venting. Only “make-up” water that is required for water that exits the facility in the product itself.",
    link: rngPresentation,
    cardsData: [
      {
        icon: sap,
        heading: "The Business Opportunity",
        text: `Food sovereignty is a growing global concern, and as climate change has permanently changed weather patterns and growing conditions, establishing a technology that is climate-independent is required to ensure reliable delivery of food. In northern climates there is significant price volatility on produce supplied from the United States, this dependency only grows with a growing population base. eGrowth solution utilizes the smallest facility footprint for maximum year round yield of produce.`,
      },
      {
        icon: sap,
        heading: "Integrated Control System",
        text: `The Company utilizes plant-centric control and automation systems. The system delivers uniformity, precision and control of all plant environments. Can complete detailed AI based data analytics to optimize growing conditions. All data is reviewed in real-time and can be remotely monitored and controlled. The system is a single solution for all climate, light, CO2, irrigation and nutrient monitoring and deployment needs.`,
      },
      {
        icon: sap,
        heading: "The Technology",
        text: `The technology enables 10 crop equivalents per year on a 1.5 acre footprint that is the equivalent to a single 200 acre conventional farm. The Company has spent over 9 months developing the engineering detailed design inclusive of both energy and mass balance simulation work. The integration of geothermal design to both heat and cool the greenhouse is the next generation step for Agri-technology development.`,
      },
      {
        icon: sap,
        heading: "The Competition",
        text: `There are over 400 greenhouses in Alberta and none utilize geothermal technology for heating and cooling requirements. 85% of existing greenhouses utilize natural gas with the remaining use either propane, oil or electricity. Of those 400 less than 1% produce lettuce and of those all production is pre-sold on long term contracts. Canada imports more than 20 million heads of lettuce per week from the United States versus one eGrowth facility will produce 17,000 head of lettuce per week.`,
      },
    ],
  },
  // {
  //   id: "#heliosx",
  //   headingGold1: "",
  //   para1: "HeliosX is an integrated lithium exploration company incorporating the latest in Direct Lithium Extraction (“DLE”) technologies to unlock 560,000 acres of potential commercial opportunities in Jujuy (Argentina), Nevada (USA) and Alberta (Canada). The Company has commercial agreements to utilize the latest in environmental technologies to remediate old mine tailings while extracting commercial precious metals, and plans to own and operate the first iodine-based gold concentrate extraction facility in North America.",
  // }
];
