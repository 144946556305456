import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "./global.css";
import { AppContainer, setConfig } from "react-hot-loader";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

setConfig({
  showReactDomPatchNotification: false,
});
ReactDOM.render(
  <AppContainer>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </AppContainer>,
  document.getElementById("root")
);
