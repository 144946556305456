import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import mouseIcon from "assets/img/revolution/mouse-icon.png";
import Fade from "react-reveal/Fade";

const HeroCreativeAgency = (props) => {
  const { title, description, videoLink, isUnmute, className } = props;
  useEffect(() => {
    const heroContent = document.querySelector(".hero-banner-content");
    const animate = heroContent.querySelectorAll(".move-up");
    animate.forEach((elem, index) => {
      elem.classList.add("animated");
    });
  }, []);
  const handleClick = () => {
    let scrollTo = document.getElementById("scrollIntoId");
    scrollTo.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  return (
    <div className={`hero-banner-wrapper ${className}`}>
      <video
        className="background-video"
        autoPlay
        loop
        muted={isUnmute ? false : true}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <div className="hero-banner-content">
              <Fade bottom>
                <h2 className="h-theme">{title}</h2>
                {/* <p className="white p-theme">{description}</p> */}
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <Fade bottom>
        <button onClick={handleClick} className="mouse-icon animate">
          <img src={mouseIcon} alt="mouse icon" />
        </button>
      </Fade>
    </div>
  );
};

export default HeroCreativeAgency;
