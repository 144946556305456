import React from "react";
import Fade from "react-reveal/Fade";
import HtmlParser from "html-react-parser";

const AboutContentOne = ({
  title,
  heading,
  subHeading,
  content,
  btnText,
  btnLink,
}) => {
  return (
    <div className="content pl_md--5 pl_sm--5">
      <Fade right>
        <div className="title--creative wow move-up">
          {title ? <h3 className="heading">{title}</h3> : ""}
          <h2
            className="h-theme"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {subHeading && (
            <>
              <p style={{ fontSize: "24px" }}>{subHeading}</p>
              <br />
            </>
          )}
        </div>
        {!(typeof content === "object") ? (
          <p className="wow move-up p-theme white">{content}</p>
        ) : (
          <ul>
            {content.map((x) => {
              return (
                <Fade right>
                  <li className="wow move-up p-theme white content--list">
                    <div>{HtmlParser(x)}</div>
                  </li>
                </Fade>
              );
            })}
          </ul>
        )}

        {/* <a
          className="about-btn wow move-up p-theme"
          href={`${process.env.PUBLIC_URL + btnLink}`}
        >
          <span>{btnText}</span>
          <i className="fa fa-arrow-right" />
        </a> */}
      </Fade>
    </div>
  );
};

export default AboutContentOne;
