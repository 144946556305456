import React from "react";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import logo from "../../assets/img/logo/helios-logo.png";

function AboutDescription({ aboutParagraphData }) {
  return (
    <div
      id="scrollIntoId"
      className="about-body pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5"
    >
      <div className="pt--50 pb--30 pt_sm--100">
        <Container>
          <div className="about-section">
            <Fade bottom>
              {aboutParagraphData.logo && <img
                className="about-logo"
                src={aboutParagraphData.logo}
                alt="Helios Logo"
              />}
            </Fade>
            <Fade bottom></Fade>
            {aboutParagraphData.link ? (
              <Fade bottom>
                <a className="link" href={aboutParagraphData.url}>
                  <h3 className=" link h-theme text-center">{aboutParagraphData.title}</h3>
                </a>
              </Fade>
            ) : (
              <h3 className="h-theme">{aboutParagraphData.title}</h3>
            )}
            <Fade bottom>
              <div className="p-theme center-wrap">
                {aboutParagraphData.more.map((x) => {
                  return (
                    <p key={`about-para${x}`} className="wrapped-text">
                      {x}
                    </p>
                  );
                })}
              </div>
            </Fade>
            {
              aboutParagraphData.link ? (
                <Fade bottom>
                  <a
                    href={aboutParagraphData.url}
                    style={{ color: "#b89535", textDecoration: "none" }}
                  >
                    Learn More {`>`}
                  </a>
                </Fade>
              ) : null
            }
          </div>
        </Container>
      </div>
    </div>
  );
}

export default AboutDescription;
