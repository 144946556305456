import GradationItem from "components/gradation-mod";
import { valuesData, defaultValues } from "data/home-page/homepage";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
// import triangle_bg from "../../assets/img/about/triangle.png";
// import lines_bg from "../../assets/img/about/light_lines.svg";

function Triangle() {
  const initialValues = defaultValues;
  const [data, setData] = useState(initialValues);
  const handleOutside = (e) => {
    if (!e.target.className.includes("count")) {
      setData(initialValues);
    }
  };
  const handleMouseEnter = (x) => {
    setData(x);
    document.querySelector(".fix-bg-img").style.animation =
      ".5s forwards hoverChange";
    document.querySelector(".tri-line-12").classList.add("hovered-line");
    document.querySelector(".tri-line-23").classList.add("hovered-line");
    document.querySelector(".tri-line-31").classList.add("hovered-line");
  };
  const handleMouseLeave = (x) => {
    // setData(initialValues);
    document.querySelector(".fix-bg-img").style.animation =
      ".5s forwards leaveChange";
    document.querySelector(".tri-line-12").classList.remove("hovered-line");
    document.querySelector(".tri-line-23").classList.remove("hovered-line");
    document.querySelector(".tri-line-31").classList.remove("hovered-line");
    // document.querySelector('.fix-bg-img').src = lines_bg;
  };
  return (
    <div
      id="handleOutside"
      onClick={(e) => handleOutside(e)}
      className="el-service-area hover-bg-img-4-container triangle--container"
    >
      <div className="container full-container">
        <div className="triangle-area row">
          <div className="triangle-area--left col-lg-6">
            <div className="title--creative text-left">
              <h3 className="heading">{data.heading}</h3>
            </div>
            <div>
              <Fade right>
                <h2 className="h-theme white">
                  {data.subheading1}
                  {/* <span className="theme-creative">{data.subheading2}</span> */}
                </h2>
              </Fade>
            </div>
            <Fade left>
              <div>
                <p className="p-theme white wow move-up">{data.content}</p>
              </div>
            </Fade>
            {data.yellowText ? (
              <Fade left>
                <div>
                  <p
                    className="p-theme gold wow move-up"
                    style={{ textTransform: "uppercase" }}
                  >
                    {data.yellowText}
                  </p>
                </div>
              </Fade>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-6">
            <div className="triangle-lines">
              {/* <img src={triangle_bg} alt="" /> */}
            </div>
            <div className="triangle-area--right">
              <div className="tri-line tri-line-12"></div>
              <div className="tri-line tri-line-23"></div>
              <div className="tri-line tri-line-31"></div>
              <div className="tri-points">
                <Fade bottom>
                  {valuesData.map((x) => {
                    return (
                      <div className="tri-point" key={x.id}>
                        <div
                          onClick={() => handleMouseEnter(x)}
                          onMouseLeave={() => handleMouseLeave(x)}
                          className={`${x.className} bk-gradation single-point`}
                        >
                          <GradationItem
                            count={x.subheading1}
                            color={x.color}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="fix-img-container" data-image={data.img}>
          {/* <img className="fix-bg-img" src={lines_bg} alt="" /> */}
          <img className="fix-bg-img" src={data.img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Triangle;
