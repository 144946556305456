import React from "react";
import "./partnership.scss";
import { Card } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Container } from "react-bootstrap";
import Box from "components/icon-box/IconBoxItemOne";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { partnershipData } from "../../data/partnerships-page/partnerships";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "auto",
    padding: "10px 10px 10px 10px",
  },
  boxes: {
    width: "33% !important",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  darkContent: {
    textAlign: "center",
    fontWeight: "600",
  },
  mandateContent: {
    padding: "10px 10px 10px 10px",
  },
  title: {
    fontSize: "26px !important",
    fontWeight: "500",
    padding: "10px 10px 10px 0px",
  },
}));

function Partnerships() {
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(2);

  return (
    <div className="part-main">
      <div>
        <img
          height="700px"
          width="100%"
          src="https://picsum.photos/id/1006/3000/2000"
          alt="another"
        />
      </div>
      {/* data, border, padding, iconType, className, showBtn */}
      <Row className="mt--40">
        {partnershipData.map((partnershipsData) => (
          <Col key={partnershipsData.id} sm={6} lg={4}>
            <Box
              data={partnershipsData}
              iconType={"img"}
              padding={true}
              border={false}
              showBtn={true}
            />
          </Col>
        ))}
      </Row>

      <Container>
        <div className="part-box">
          <h2>Our Partnerships</h2>
          <Card className="part-card">
            <div className="part-single-box">
              <h4>HelioX</h4>
              <hr />
              <Grid item container>
                <Grid xs={3} lg={3} sm={3}>
                  <img
                    src="https://picsum.photos/200"
                    alt="testing"
                    width="auto"
                    height="200px"
                  />
                </Grid>
                <Grid xs={9} lg={9} sm={9}>
                  <p>
                    HeliosX is an integrated lithium exploration company
                    incorporating the latest in Direct Lithium Extraction
                    (“DLE”) technologies to unlock 560,000 acres of potential
                    commercial opportunities in Jujuy (Argentina), Nevada (USA)
                    and Alberta (Canada). The Company has commercial agreements
                    to utilize the latest in environmental technologies to
                    remediate old mine tailings while extracting commercial
                    precious metals, and plans to own and operate the first
                    iodine-based gold concentrate extraction facility in North
                    America.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Card>
          <Card className="part-card">
            <div className="part-single-box">
              <h4>HelioX</h4>
              <hr />
              <Grid item container>
                <Grid xs={3} lg={3} sm={3}>
                  <img
                    src="https://picsum.photos/200"
                    alt="testing"
                    width="auto"
                    height="200px"
                  />
                </Grid>
                <Grid xs={9} lg={9} sm={9}>
                  <p>
                    HeliosX is an integrated lithium exploration company
                    incorporating the latest in Direct Lithium Extraction
                    (“DLE”) technologies to unlock 560,000 acres of potential
                    commercial opportunities in Jujuy (Argentina), Nevada (USA)
                    and Alberta (Canada). The Company has commercial agreements
                    to utilize the latest in environmental technologies to
                    remediate old mine tailings while extracting commercial
                    precious metals, and plans to own and operate the first
                    iodine-based gold concentrate extraction facility in North
                    America.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Card>
          <Card className="part-card">
            <div className="part-single-box">
              <h4>HelioX</h4>
              <hr />
              <Grid item container>
                <Grid xs={3} lg={3} sm={3}>
                  <img
                    src="https://picsum.photos/200"
                    alt="testing"
                    width="auto"
                    height="200px"
                  />
                </Grid>
                <Grid xs={9} lg={9} sm={9}>
                  <p>
                    HeliosX is an integrated lithium exploration company
                    incorporating the latest in Direct Lithium Extraction
                    (“DLE”) technologies to unlock 560,000 acres of potential
                    commercial opportunities in Jujuy (Argentina), Nevada (USA)
                    and Alberta (Canada). The Company has commercial agreements
                    to utilize the latest in environmental technologies to
                    remediate old mine tailings while extracting commercial
                    precious metals, and plans to own and operate the first
                    iodine-based gold concentrate extraction facility in North
                    America.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
        <div className="mandates-main-container">
          <h2>Completed Mandates</h2>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={spacing}>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => (
                  <Grid key={value} className={classes.boxes} item>
                    <Paper className={classes.paper}>
                      <div className={classes.title}>
                        <span>Oman</span>
                      </div>
                      <img
                        src="https://picsum.photos/200"
                        alt="mandates"
                        width="100%"
                      />
                      <div className={classes.mandateContent}>
                        <p className={classes.darkContent}>
                          -Henan Sunrise Holding Group-
                        </p>
                        <p className={classes.paraContent}>
                          Financial advisor for the development of the Green
                          Loan Guarantee Program (GLGP) both for Indigenous
                          communities as well as small/medium/large scale
                          emitters. In addition completed program design work on
                          the Property Assessed Clean Energy (PACE) program.
                        </p>
                        <p className={classes.darkContent}>
                          -$400 million of Loan Guarantees-
                        </p>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default Partnerships;
